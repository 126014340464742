import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
/*export const panier = { 
  idclient: "1", 
  idshop: "1",
  demandeur:0,
  totalcom: 0.00,
  idtypelivraison: 1,
  listeProduits: [{
    idproduit: Number,
    nomproduit: String,
    quantiteproduit: 0,
    prixproduit:0.00,
    taille:'',
    prixtotalSupplement:0.00,
    listeSupplements:[{
      nomcomposant: '',
      prixcomposant: 0.00
    
    }]
  }],
  
};*/

export const panierArchive = { 
  idclient: null, 
  idshop : "1",
  demandeur:0,
  totalcom: 0.00,
  idtypelivraison: 3,
  heurelivraison:null,
  listeProduits: [{
    idproduit: Number,
    nomproduit: String,
    imageproduit: String,
    quantiteproduit: 0,
    prixproduit:0.00,
    taille:'',
    prixtotalSupplement:0.00,
    listeSupplements:[{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00
    
    }],
    listeARetirer:[{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00    
    }],
    listeOffre: [{
      idproduitOffre: Number,
      nomproduitOffre: '',
      taille: '',
      prixproduitOffre: 0.00,
      quantiteOffre: Number,
      listeprix: []
    }]
  }],
  
};
export const panier = {
  idclient: null,
  idshop: 1,
  demandeur: 0,
  totalcom: 0.00,
  idtypelivraison: 3,
  heurelivraison: null,
  listeProduits: [{
    idproduit: Number,
    nomproduit: String,
    imageproduit: String,
    quantiteproduit: 0,
    prixproduit: 0.00,
    taille: '',
    prixtotalSupplement: 0.00,
    listeIngredients: [{
      idCombos: null,
      nomCombos: null,
      prixCombos: 0.00,
      quantiteCombos: 0
    }],
    listeSupplements: [{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00

    }],
    listeARetirer: [{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00
    }],
    listeOffre: [{
      idproduitOffre: Number,
      nomproduitOffre: '',
      taille: '',
      prixproduitOffre: 0.00,
      quantiteOffre: Number,
      listeprix: []
    }]
  }],

};
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Commandes',
      url: '/listecommandesclients',    
      icon: 'list'
    },
    {
      title: 'Archives Commandes',
      url: '/archives-commandes',    
      icon: 'trash'
    },
    {
      title: 'Clients',
      url: '/listeclients',
      icon: 'people'
    },
    {
      title: 'Produits',
      url: '/listeproduit',
      icon: 'podium'
    },
    {
      title: 'Ingrédients',
      url: '/listeingredients',
      icon: 'keypad'
    },
    {
      title: 'Catégories',
      url: '/listecategories',
      icon: 'grid'
    },
    {
      title: 'Types de produit',
      url: '/listetypeproduit',
      icon: 'images'
    },
    {
      title: 'Notre RollingPizza',
      url: '/shop',
      icon: 'images'
    },
    {
      title: 'Passer une commande',
      url: '/tabs',
      icon: 'paper-plane'
    }   
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('home')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
